<template>
  <div class="room-select" @click.stop="() => {}">
    <div @click="showBtn">
      <CSSelect
        height="40px"
        i-width="36px"
        :style="{
          width: '200px',
          color: checkedRoomNames.length > 0 ? '#000' : '#999',
        }"
      >
        <div class="checked-rooms">
          {{
            checkedRoomNames.length > 0 ? checkedRoomNames.toString() : "请选择"
          }}
        </div>
      </CSSelect>
    </div>
    <div class="room-select-panel" v-show="isShowPanel">
      <div class="room-select-panel-content">
        <div class="floor-info">
          <div v-for="building in roomList" :key="building.buildingId">
            <div class="building-checkbox-panel">
              <div @click="changeUnfold('unflodBuilding', building.buildingId)">
                <div
                  :class="[
                    'glyphicon',
                    'glyphicon-triangle-right',
                    {
                      rotate: unflodBuilding.includes(building.buildingId),
                    },
                  ]"
                ></div>
                <img
                  v-if="buildListS.includes('' + building.id)"
                  src="../../public/common/static/img/checkbox/checked.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../public/common/static/img/checkbox/checkedWhite.png"
                  alt=""
                />
                {{ building.buildingName }}
              </div>
              <div
                class="floor-checkbox-panel"
                v-for="floor in building.floors"
                :key="floor.floor"
              >
                <template v-if="unflodBuilding.includes(building.buildingId)">
                  <div @click="changeUnBtn(building, floor)">
                    <div
                      style="margin-left: 20px"
                      :class="[
                        'glyphicon',
                        {
                          rotate: unfoldFloor.includes(floor.floor),
                        },
                      ]"
                    ></div>
                    <img
                      v-if="
                        checkedFloors.includes(building.id + '-' + floor.floor + '-'+ building.buildingId)
                      "
                      src="../../public/common/static/img/checkbox/checked.png"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../public/common/static/img/checkbox/checkedWhite.png"
                      alt=""
                    />
                    {{ floor.floor }}层
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import { queryBuildingRoomsWUrl, queryRoomRentUrld,queryBuildingFloorUrl } from "@/requestUrl";
import { ROOM_TEMPORARY_RESIDENT } from "@/constant";

export default {
  name: "AllRoomSeSelect",
  props: {
    relevanceBuilding:Array,
    relevance:Array,
    isDisable: {
      type: Boolean,
      default: false,
    },
    topBuilding: {
      type: Number,
      default: 1,
    },
    selectedRooms: Array,
    isSelectedRooms: Array,
    // checkedRoomNames: Array,
    isUrl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CSSelect,
  },
  data() {
    return {
      ROOM_TEMPORARY_RESIDENT,
      isShowPanel: false,
      unfoldFloor: [],
      unflodBuilding: [],
      roomList: [],
      checkedRoomIds: [],
      checkedFloors: [],
      checkedBuildings: [],
      checkedRoomNames: [],
      checkedRooms: [],
      builtUpArea: [],
      buildListS:[]
    };
  },
  watch: {
    checkedFloors(newVal){
      this.buildListS = [];
      newVal.forEach(item => {
        let ar = item.split('-');
        this.buildListS.push(ar[0]);
      })
      console.log('选择的商机');
      console.log(this.buildListS);
    }
  },
  methods: {
    showBtn() {
      if (this.isDisable) {
        this.isShowPanel = true;
      }
    },

    // 查询楼栋楼层
    async queryRoom() {
      let url = queryBuildingFloorUrl;
      if (this.isUrl) {
        url = queryRoomRentUrld;
      }
      return this.$fly
        .post(url, {
          regionCode: this.$vc.getCurrentRegion().communityId,
          state: 1,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          let floorNum = this.topBuilding || 1;
          res.data = res.data.map((item) => {
            if (item.floors) {
              return {
                ...item,
                floors: item.floors.filter(
                  (res) => res.floor > 1 && res.floor <= floorNum
                ),
              };
            }
            return item;
          });
          console.log(res.data);
          this.roomList = res.data;
          return res.data;
        });
    },
    /**
     * 修改展开/关闭
     * @param {String} property 操作的属性名称
     * @param {Number} id
     *  */
    changeUnfold(property, id) {
      if (this[property].includes(id)) {
        this[property].splice(this[property].indexOf(id), 1);
      } else {
        this[property].push(id);
      }
    },
    changeUnBtn(building, floor) {
      // console.log(`${building.buildingId}-${floor.floor}`);
      let isSelect = this.checkedFloors.includes(
        `${building.id}-${floor.floor}-${building.buildingId}`
      );
      if (isSelect) {
        this.checkedFloors = this.checkedFloors.filter((item) => {
          return `${building.id}-${floor.floor}-${building.buildingId}` != item;
        });
        this.checkedRoomNames = this.checkedRoomNames.filter((item) => {
          return `${building.buildingName}${floor.floor}层` != item;
        });
      } else {
        this.checkedFloors = [
          ...this.checkedFloors,
          `${building.id}-${floor.floor}-${building.buildingId}`,
        ];
        this.checkedRoomNames = [
          ...this.checkedRoomNames,
          `${building.buildingName}${floor.floor}层`,
        ];
      }
      this.$emit("changeContractRooms", this.checkedFloors);
      // console.log(this.checkedFloors);
    },
    setBuildingName(building) {
      this.checkedRoomNames.push(
        `${building.buildingName ? building.buildingName + "-" : ""}${
          building.roomCode || ""
        }`
      );
    },
  },
  mounted() {
    document
      .querySelector(".editOver-container")
      .addEventListener("click", () => {
        this.isShowPanel = false;
      });
    this.$vc.on("allRoomSelectContract", "selcet", (item) => {
      this.isShowPanel = false;
    });
  },
  created() {
    this.checkedRoomNames = this.relevanceBuilding;
    if(this.relevance){
      this.relevance.forEach(item => {
          item.relevanceFloor.forEach(res => {
            this.checkedFloors.push(`${item.relevanceBuildingId}-${res}-${item.relevanceCode}`)
          })
      })
      this.$emit("changeContractRooms", this.checkedFloors);
    }
    this.$vc.on("changeFloor", (item) => {
      this.checkedFloors = item;
      this.checkedRoomNames = [];
      this.$emit("changeContractRooms", this.checkedFloors);
      this.queryRoom();
    });
    this.queryRoom();
  },
};
</script>
<style>
.room-select {
  position: relative;
}

.room-select .custom-input {
  border: 1px solid #979797 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checked-rooms {
  max-width: 220px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.room-select-panel {
  position: absolute;
  top: 45px;
  left: 0;
  background: #fff;
  width: 255px;
  height: 280px;
  z-index: 2;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.room-select-panel-header {
  text-align: center;
  margin-bottom: 25px;
}

.room-select-panel-header span {
  font-size: 24px;
  display: inline-block;
  position: relative;
  width: 48px;
  height: 33px;
  line-height: 33px;
}

.glyphicon.glyphicon-triangle-right {
  font-size: 10px;
  margin-right: 8px;
  vertical-align: middle;
}

.glyphicon.glyphicon-triangle-right.rotate {
  -webkit-animation: arrowRotate 0.1s linear 1;
  animation: arrowRotate 0.1s linear 1;
  transform: rotate(90deg);
}

@-webkit-keyframes arrowRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
  }
}

@keyframes arrowRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
  }
}

.room-select-panel-header span:not(:last-of-type) {
  margin-right: 99px;
}

.room-select-panel-header span.active::after {
  position: absolute;
  width: 60px;
  height: 4px;
  content: " ";
  background: #1ab394;
  border-radius: 2px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.room-select-panel-content {
  padding: 14px;
  height: 100%;
  overflow: auto;
  color: #000;
}

.room-select-panel input[type="checkbox"] {
  vertical-align: middle;
}

.floor-checkbox-panel input[type="checkbox"] {
  margin-right: 10px;
}

.floor-checkbox-panel {
  padding-left: 20px;
}

.room-checkbox-panel {
  padding-left: 40px;
  margin-bottom: 0;
}

.custom-input {
  width: 250px !important;
  padding: 0px 10px 0 10px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  max-width: 234px;
}
</style>
